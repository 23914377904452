import React, { useEffect, useRef, useState } from 'react';
import { useScript, useStyle } from './useScriptAndStyle';
import { getLocationList } from '../../sitemanagement/actionMethods/actionMethods';
import Light from '../../assets/images/light.png';
import Street from '../../assets/images/street.png';
import Satellite from '../../assets/images/satellite.png';
import Outdoors from '../../assets/images/outdoor.png';
import Dark from '../../assets/images/dark.png';
import MapIcon from '../../assets/images/placeholder-map.png';
import '../style/styles.scss';
import { Button, Modal, Select, Table } from 'antd';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { 
    CreteGeoMapLocation, 
    CreteGeoMapLocationBoundary, 
    CreteGeoMapSpaceID, 
    getLocationCordinate, 
    getLocationCordinateV1, 
    removeMapLocation, 
    removeMapLocationBoundary 
} from '../actionMethods/actionMethods';
import ReactModal from 'react-modal';
import WarningModal from '../../components/WarningModal';
import LoaderSpinner from '../../assets/images/loader.svg';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        maxHeight: '90%',
    },
};

const MapComponentV3 = () => {
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin') != null 
        && localStorage.getItem('userLoginDetailsTraceAdmin') !== 'loggedIn' 
        ? JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')) 
        : [];
    let session = userDetails.session != null ? userDetails.session : 'ezrsrdfghawtsetrrAZSXCxfa';

    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const markersRef = useRef({});
    const existingPolygons = useRef([]); 
    const [draw, setDraw] = useState(null); 
    const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v11'); 
    const [locationList, SetLocationList] = useState([]);
    const [loader, SetLoader] = useState(true);
    const [currentMode, setCurrentMode] = useState('Streets');
    const [currentModeImg, setCurrentModeImg] = useState(Street);
    const [widthBox, SetWidthBox] = useState(80);
    const [locationSelected, SetLocationSelected] = useState('');
    const [modalLocation, SetModalLocation] = useState(false);
    const [modalSucess, SetModalSucess] = useState(false);
    const [modalError, SetModalError] = useState(false);
    const [title, SetTitle] = useState('');
    const [description, SetDescription] = useState('');
    const [locationCordinates, SetLocationCordinates] = useState([]);
    const [locationPoint, setLocationPoint] = useState([]);
    const [existLocation, SetExistLocation] = useState([]);
    const [deleteModal, SetDeleteModal] = useState(false);
    const [deleteModalBoundary, SetDeleteModalBoundary] = useState(false);
    const [loaderAPI, SetLoaderAPI] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    let org_id = userDetails.org_id ? userDetails.org_id : "";

    const changeMapStyle = (newStyle, title, image) => {
        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image);
        setTimeout(() => SetWidthBox(80), 500);

        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };

    const mapBoxMode = [
        { function: 'mapbox://styles/mapbox/streets-v11', title: "Streets", image: Street },
        { function: 'mapbox://styles/mapbox/outdoors-v11', title: "Outdoors", image: Outdoors },
        { function: 'mapbox://styles/mapbox/light-v10', title: "Light", image: Light },
        { function: 'mapbox://styles/mapbox/dark-v10', title: "Dark", image: Dark },
        { function: 'mapbox://styles/mapbox/satellite-v9', title: "Satellite", image: Satellite },
    ];

    const handleCloseModalSucess = () => {
        SetModalSucess(false);
        SetModalLocation(false);
        SetDeleteModal(false);
        SetDeleteModalBoundary(false);
        SetLocationSelected('');
    };

    const handleCloseModalError = () => {
        SetModalError(false);
    };

    useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useScript('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.js');
    useScript('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js');
    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useStyle('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.css');
    useStyle('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    const CustomDirectSelect = {
      onSetup() {
        return {};
      },
      onClick() {
        return;
      },
      onStop() {
        return;
      },
      toDisplayFeatures(state, geojson, display) {
        // Ensure features are displayed but not editable
        display(geojson);
      },
    };

    const CustomSimpleSelect = {
      onSetup() {
        return {};
      },
      onClick() {
        return;
      },
      onStop() {
        return;
      },
      toDisplayFeatures(state, geojson, display) {
        // Ensure features are displayed but not editable
        display(geojson);
      },
    };

    useEffect(() => {
        if (window.mapboxgl && window.MapboxDraw && !loader) {
            window.mapboxgl.accessToken = 'pk.eyJ1IjoiZmFpc2FsMTc3NDAiLCJhIjoiY2p4c3M2dG42MG41bTNndDVzNmF4bHVtbiJ9.ENo4nKK3gSEasAePjO3JDw';

            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                center: [106.618638, -6.154848],
                zoom: 18,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');

            const geocoder = new window.MapboxGeocoder({
                accessToken: window.mapboxgl.accessToken,
                mapboxgl: window.mapboxgl,
                marker: { color: 'orange' },
            });

            mapRef.current.addControl(geocoder, 'top-left');

            geocoder.on('result', (e) => {
                const coordinates = e.result.geometry.coordinates;
                console.log('Selected location:', e.result.place_name, 'Coordinates:', coordinates);
                mapRef.current.flyTo({ center: coordinates, zoom: 12 });
            });

            const drawInstance = new window.MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: false,
                    circle:false,
                    simple_select:false,
                    direct_select:false,
                    line_string: false,
                    trash: false,
                    visualizePitch: false,
                            point: false, // Add the point control for the location marker
                    
                },
                modes: {
                  ...window.MapboxDraw.modes,
                      direct_select: CustomDirectSelect, // Override direct_select
                      simple_select: CustomSimpleSelect, 
              },
            });

            mapRef.current.addControl(drawInstance);
            setDraw(drawInstance);
        }
    }, [selectedStyle, loader]);


//  const plotLocationMarkers = (locations) => {
//   locations.forEach((location) => {
//     const { position, name, unique_loc_id } = location;

//     // Create a marker element with a custom icon
//     console.log("plotLocationMarkers",position, name, unique_loc_id )
//     const markerElement = document.createElement('div');
//     markerElement.className = 'custom-marker';
//     markerElement.style.backgroundImage = `url('${MapIcon}')`; // Set your icon path
//     markerElement.style.backgroundSize = 'cover';
//     markerElement.style.width = '30px';
//     markerElement.style.height = '30px';

//     // Add the marker to the map
//    const marker =  new window.mapboxgl.Marker(markerElement)
//       .setLngLat([position.lng, position.lat])
//       .setPopup(new window.mapboxgl.Popup({ offset: 25 }) // Tooltip on click
//         .setHTML(`<strong>${name}</strong>`)) // Tooltip content
//       .addTo(mapRef.current);
//   markersRef.current[unique_loc_id] = marker; // Store marker by location I
//   });
 

  
// };

const plotLocationMarkers = (locations) => {
  locations.forEach((location) => {
    const { position, name, unique_loc_id } = location;

    if (!position || isNaN(position.x) || isNaN(position.z)) {
      console.error(`Invalid position for ${name} (${unique_loc_id}):`, position);
      return; // Skip this location
    }

    const lng = parseFloat(position.x);
    const lat = parseFloat(position.z);

    if (isNaN(lng) || isNaN(lat)) {
      console.error(`Invalid coordinates for ${name} (${unique_loc_id}):`, position);
      return;
    }

    // Create a marker element with a custom icon
    const markerElement = document.createElement('div');
    markerElement.className = 'custom-marker';
    markerElement.style.backgroundImage = `url('${MapIcon}')`; // Set your icon path
    markerElement.style.backgroundSize = 'cover';
    markerElement.style.width = '10px';
    markerElement.style.height = '10px';

    // Add the marker to the map
    const marker = new window.mapboxgl.Marker(markerElement)
      .setLngLat([lng, lat])
      .setPopup(
        new window.mapboxgl.Popup({ offset: 25 }).setHTML(`<strong>${name}</strong>`)
      ) // Tooltip content
      .addTo(mapRef.current);

    markersRef.current[unique_loc_id] = marker; // Store marker by location ID
  });
};



    const fetchData = async () => {
        try {
            let requestBody = {
                session: session,
                org_id: org_id,
            };

            const res = await getLocationList(requestBody);
            let locArray = [];
            let locPointArray = [];
            let locationCordinates = [];

            if (res.status == 200 || res.code === 200) {
                SetLocationList(res.data);
                locArray = res.data;
            }

            const res2 = await getLocationCordinate(requestBody);
            if (res2.status === 200) {
                console.log("here location id is mapping",locArray,res2.data)
                locationCordinates = res2.data.map(elm=>{
                  let find = locArray.find(el=>el.tag_serial === elm.location_serial);
                  return {...elm,location_id:find?.id}
                });
                SetLocationCordinates(locationCordinates);
                console.log("locationCordinates",locationCordinates)
                SetExistLocation(res2.data);
                SetLoader(false);
            }

            const res3 = await getLocationCordinateV1(requestBody);
            if (res3.status === 200) {
                locPointArray = res3.data.location;
                setLocationPoint(res3.data.location);
                console.log("locationPoint",res3.data.location)
                plotLocationMarkers(res3.data.location)
            } else if (res3.status === 201 && res3.message === "space id is not present") {
                let requestBodySpace = {
                    session: session,
                    org_id: org_id,
                    space_id: "staticSpaceMapBox" + userDetails.org_id,
                };
                await CreteGeoMapSpaceID(requestBodySpace);
            }


        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // const handleAddPoint = (locationId,name) => {
    //     console.log("location ", locationId);
    //     SetLocationSelected(locationId);
    //     draw.changeMode('draw_point');

    //     mapRef.current.on('draw.create', async (e) => {
    //         const pointCoordinates = e.features[0].geometry.coordinates;

    //         let requestBody = {
    //             location_id: locationId,
    //             org_id: org_id,
    //             unique_loc_id: locationId,
    //             position: { lng: pointCoordinates[0], lat: pointCoordinates[1] },
    //             session: session,
    //         };
    //         console.log("draw.create", requestBody, pointCoordinates);

    //         try {
    //             SetLoaderAPI(true);
    //             let res = await CreteGeoMapLocation(requestBody);
    //             if (res.status == 200) {
    //                 SetLoaderAPI(false);
    //                 setLocationPoint((prev) => [...prev, { location_id: locationId, position: pointCoordinates }]);
    //                 SetTitle(<h4>Point added successfully</h4>);
    //                 SetModalSucess(true);
    //             } else {
    //                 SetLoaderAPI(false);
    //                 SetTitle(<h4>Failed to add location</h4>);
    //                 SetDescription(res.message);
    //                 SetModalError(true);
    //             }
    //         } catch (error) {
    //             console.error("Error adding point:", error);
    //             SetModalError(true);
    //             SetTitle(<h4>Failed to add point</h4>);
    //         }
    //     });
    // };

const handleAddPoint = (locationId,name) => {
  SetLocationSelected(locationId);
  draw.changeMode('draw_point'); // Enable point drawing mode

  mapRef.current.once('draw.create', async (e) => {
    const pointCoordinates = e.features[0].geometry.coordinates;
    const requestBody = {
      location_id: locationId,
      org_id: org_id,
      unique_loc_id: locationId,
      position: { lng: pointCoordinates[0], lat: pointCoordinates[1] },
      session: session,
    };

    try {
      SetLoaderAPI(true);
      const res = await CreteGeoMapLocation(requestBody);
      if (res.status == 200) {
        SetLoaderAPI(false);
        setLocationPoint((prev) => [
          ...prev,
          { location_id: locationId, position: pointCoordinates },
        ]);

        // Add marker to the map and store it in markersRef
        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.backgroundImage = `url('${MapIcon}')`;
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.width = '10px';
        markerElement.style.height = '10px';

        const marker = new window.mapboxgl.Marker(markerElement)
          .setLngLat(pointCoordinates)
          .setPopup(new window.mapboxgl.Popup({ offset: 25 }).setHTML(`<strong>${name}</strong>`))
          .addTo(mapRef.current);

        markersRef.current[locationId] = marker; // Store marker by location ID

        SetTitle(<h4>Location added successfully</h4>);
        SetModalSucess(true);
      } else {
        SetLoaderAPI(false);
        SetTitle(<h4>Failed to add Location</h4>);
        SetDescription(res.message);
        SetModalError(true);
      }
    } catch (error) {
      console.error("Error adding point:", error);
      SetLoaderAPI(false);
      SetModalError(true);
      SetTitle(<h4>Failed to add Location Cordinates</h4>);
    }
  });
};

const handleAddBoundary = (locationId,name) => {
  SetLocationSelected(locationId);
  draw.changeMode('draw_polygon'); // Enable polygon drawing mode

  // mapRef.current.once('draw.create', async (e) => {
  //   const polygonCoordinates = e.features[0].geometry.coordinates;
  //   console.log("polygonCoordinates",polygonCoordinates)
  //   if (polygonCoordinates.length < 4 || 
  //       (polygonCoordinates[0][0] !== polygonCoordinates[polygonCoordinates.length - 1][0] || 
  //        polygonCoordinates[0][1] !== polygonCoordinates[polygonCoordinates.length - 1][1])) {
  //       SetTitle(<h4>Invalid Polygon: Ensure it is closed and has at least 3 unique points</h4>);
  //       SetModalError(true);
  //       draw.deleteAll(); // Remove the invalid polygon from the map
  //       return;
  //   }


  //   // Check for overlap using Turf.js
  //   const newPolygon = window.turf.polygon(polygonCoordinates);
  //   const isOverlap = existingPolygons.current.some((existingPolygon) => {
  //     const existingPolyFeature = window.turf.polygon(existingPolygon);
  //     return window.turf.booleanOverlap(newPolygon, existingPolyFeature);
  //   });

  //   if (isOverlap) {
  //     SetTitle(<h4>Boundary Overlap Detected</h4>);
  //     SetModalError(true);
  //     draw.deleteAll(); // Remove the overlapping polygon from the map
  //     return;
  //   }

  //   const requestBody = {
  //     session: userDetails.session,
  //     org_id: org_id,
  //     location_id: locationId,
  //     position: polygonCoordinates,
  //     sub_org_id: userDetails.sub_org_id,
  //   };

  //   try {
  //     SetLoaderAPI(true);
  //     const res = await CreteGeoMapLocationBoundary(requestBody);
  //     console.log("res",res);
  //     if (res.status == 200) {
  //       SetLoaderAPI(false);
  //       SetLocationCordinates((prev) => [
  //         ...prev,
  //         {location_id:locationId,location_serial: locationId, coordinates: polygonCoordinates },
  //       ]);

  //       SetTitle(<h4>Boundary added successfully</h4>);
  //       SetModalSucess(true);

  //       // Add the polygon to the map with green border
  //       // draw.deleteAll();
  //       draw.deleteAll(); // Remove the overlapping polygon from the map
  //       addPolygonWithLabel(polygonCoordinates, name, locationId);


  //       // Store the polygon coordinates to check overlap in future additions
  //       existingPolygons.current.push([polygonCoordinates]);
  //     } else {
  //       SetLoaderAPI(false);
  //       SetTitle(<h4>Failed to add boundary</h4>);
  //       SetDescription(res.message);
  //       SetModalError(true);
  //     }
  //   } catch (error) {
  //     console.error("Error adding boundary:", error);
  //     SetLoaderAPI(false);
  //     SetTitle(<h4>Failed to add boundary</h4>);
  //     SetModalError(true);
  //   }
  // });
mapRef.current.once('draw.create', async (e) => {
    const polygonCoordinates = e.features[0].geometry.coordinates[0]; // Get the outer ring

    // Ensure polygon has at least 4 positions and is closed (first point = last point)
    if (polygonCoordinates.length < 4 || 
        (polygonCoordinates[0][0] !== polygonCoordinates[polygonCoordinates.length - 1][0] || 
         polygonCoordinates[0][1] !== polygonCoordinates[polygonCoordinates.length - 1][1])) {
        SetTitle(<h4>Invalid Polygon: Ensure it is closed and has at least 3 unique points</h4>);
        SetModalError(true);
        draw.deleteAll(); // Remove the invalid polygon from the map
        return;
    }

    // Proceed with adding the boundary if valid
    const requestBody = {
        session: userDetails.session,
        org_id: org_id,
        location_id: locationId,
        position: [polygonCoordinates],
        sub_org_id: userDetails.sub_org_id,
    };

    try {
        SetLoaderAPI(true);
        const res = await CreteGeoMapLocationBoundary(requestBody);
        if (res.status === 200) {
            SetLoaderAPI(false);


            let location_serial=  [...locationList].find(el=>el.id == locationId)?.tag_serial
            SetLocationCordinates((prev) => [
                ...prev,
                { location_id: locationId, coordinates: polygonCoordinates,location_serial:location_serial,org_id:userDetails.org_id,id:locationId },
            ]);
            console.log("temp push",[...locationList],[...existLocation,{ location_id: locationId, coordinates: polygonCoordinates,location_serial:location_serial,org_id:userDetails.org_id,id:locationId }])
            SetExistLocation((prev) => [
                ...prev,
                { location_id: locationId, coordinates: polygonCoordinates,location_serial:location_serial,org_id:userDetails.org_id,id:locationId },
            ])


            SetTitle(<h4>Boundary added successfully</h4>);
            SetModalSucess(true);

            draw.deleteAll(); // Clear the drawn polygon
            addPolygonWithLabel([polygonCoordinates], name, locationId);

            existingPolygons.current.push(polygonCoordinates); // Store the valid polygon
        } else {
            SetLoaderAPI(false);
            SetTitle(<h4>Failed to add boundary</h4>);
            SetDescription(res.message);
            SetModalError(true);
        }
    } catch (error) {
        console.error("Error adding boundary:", error);
        SetLoaderAPI(false);
        SetTitle(<h4>Failed to add boundary</h4>);
        SetModalError(true);
    }
});
};

    const handleDeletePoint = (locationId, name) => {
        console.log("delete id", locationId, name);
        SetLocationSelected(locationId);
        SetTitle(<span style={{ color: "red" }}>{name}</span>);
        SetDeleteModal(true);
    };

    const handleDeleteBoundary = (locationId, name) => {
        console.log("delete id", locationId, name);
        SetLocationSelected(locationId);
        SetTitle(<span style={{ color: "red" }}>{name}</span>);
        SetDescription('');
        SetDeleteModalBoundary(true);
    };

    const renderActions = (locationId, type, name) => {
        const isPointAdded = locationPoint.some((point) => (point.location_id === locationId) || point.location_serial===locationId);
        const isBoundaryAdded = existLocation.some(
            (coord) => coord.location_serial === locationList.find((loc) => loc.id === locationId)?.tag_serial
        );
        console.log("locationId, type, name",name,isBoundaryAdded,existLocation)

        if (!isPointAdded && type === "point") {
            return (
                <Button onClick={() => handleAddPoint(locationId, name)} type="primary" size="small" className='mapbox-gl-draw_point'>
                </Button>
            );
        } else if (isPointAdded && type === "point") {
            return (
                <Button 
                  disabled={isBoundaryAdded}
                onClick={() => handleDeletePoint(locationId, name)} type="danger" size="small" className='mapbox-gl-draw_trash'>
                </Button>
            );
        }

        if (!isBoundaryAdded && type === "cordinate" && isPointAdded) {
            return (
                <Button className='mapbox-gl-draw_polygon' onClick={() => handleAddBoundary(locationId, name)} type="primary" size="small">
                </Button>
            );
        } else if (isBoundaryAdded && type === "cordinate" && isPointAdded) {
            return (
                <Button className='mapbox-gl-draw_trash' onClick={() => handleDeleteBoundary(locationId, name)} type="danger" size="small">
                </Button>
            );
        }

        return;
    };

//     useEffect(() => {
//         // Display existing coordinates on the map
//         if (locationCordinates.length > 0 && mapRef.current && !loader && locationPoint.length > 0) {
//             console.log("Else if true " ,locationCordinates.length > 0)
//             existingPolygons.current=[];
//             locationCordinates.forEach((location) => {
//                 const coordinates = location.coordinates;
//                 let loc = [...locationList].find((el) => el.tag_serial === location.location_serial);
//                 let locPoint = [...locationPoint].find((el) => el.location_id === location.location_id);

                
// if (mapRef.current.isStyleLoaded()) {
    
//   existingPolygons.current.push([coordinates]);
//                     mapRef.current.addLayer({
//                         id: `polygon-${loc.id}`,
//                         type: 'fill',
//                         source: {
//                             type: 'geojson',
//                             data: {
//                                 type: 'Feature',
//                                 geometry: {
//                                     type: 'Polygon',
//                                     coordinates: [coordinates],
//                                 },
//                             },
//                         },
//                         layout: {},
//                         paint: {
//                             'fill-opacity': 0,
//                         },
//                     });

//                     mapRef.current.addLayer({
//                         id: `outline-${loc.id}`,
//                         type: 'line',
//                         source: {
//                             type: 'geojson',
//                             data: {
//                                 type: 'Feature',
//                                 geometry: {
//                                     type: 'Polygon',
//                                     coordinates: [coordinates],
//                                 },
//                             },
//                         },
//                         layout: {},
//                         paint: {
//                             'line-color': '#4ec274',
//                             'line-width': 1,
//                         },
//                     });

//                    addPolygonWithLabel([coordinates], loc.location_name, loc.id,locPoint?[parseFloat(locPoint.position.x),parseFloat(locPoint.position.z)]:null);
    
// } else {
//     console.log("Map style else")
//      mapRef.current.on('style.load', () => {
//                     console.log("loaddded or not")
//                     existingPolygons.current.push([coordinates]);
//                     mapRef.current.addLayer({
//                         id: `polygon-${loc.id}`,
//                         type: 'fill',
//                         source: {
//                             type: 'geojson',
//                             data: {
//                                 type: 'Feature',
//                                 geometry: {
//                                     type: 'Polygon',
//                                     coordinates: [coordinates],
//                                 },
//                             },
//                         },
//                         layout: {},
//                         paint: {
//                             'fill-opacity': 0,
//                         },
//                     });

//                     mapRef.current.addLayer({
//                         id: `outline-${loc.id}`,
//                         type: 'line',
//                         source: {
//                             type: 'geojson',
//                             data: {
//                                 type: 'Feature',
//                                 geometry: {
//                                     type: 'Polygon',
//                                     coordinates: [coordinates],
//                                 },
//                             },
//                         },
//                         layout: {},
//                         paint: {
//                             'line-color': '#4ec274',
//                             'line-width': 1,
//                         },
//                     });

//                    addPolygonWithLabel([coordinates], loc.location_name, loc.id,locPoint?[parseFloat(locPoint.position.x),parseFloat(locPoint.position.z)]:null);
//                 });
// }
               
//             });
//         }else{
//             console.log("Else 1" ,locationCordinates.length > 0)
//             console.log("Else 2" ,mapRef.current)
//             console.log("Else 3" ,!loader)
//             console.log("Else 4" ,locationPoint.length > 0)
//             console.log("Else 5" ,locationCordinates.length > 0 && mapRef.current && !loader && locationPoint.length > 0)
//         }
//     }, [locationCordinates,mapRef.current, loader, locationList,locationPoint]);

useEffect(() => {
  if (!mapRef.current || loader || locationCordinates.length === 0 || locationPoint.length === 0) {
    console.log("Skipping execution: conditions not met", {
      mapExists: !!mapRef.current,
      loader,
      hasCoordinates: locationCordinates.length > 0,
      hasPoints: locationPoint.length > 0,
    });
    return;
  }

  console.log("Checking if Mapbox style is loaded...");

  const processPolygons = () => {
    console.log("Adding polygons...");
    existingPolygons.current = [];

    locationCordinates.forEach((location) => {
      const coordinates = location.coordinates;
      const loc = locationList.find((el) => el.tag_serial === location.location_serial);
      const locPoint = locationPoint.find((el) => el.location_id === location.location_id);
      const parsedLocPoint = locPoint ? [parseFloat(locPoint.position.x), parseFloat(locPoint.position.z)] : null;

      if (!loc || !coordinates) {
        console.warn("Skipping invalid location data", location);
        return;
      }

      // Avoid duplicate layers
      if (mapRef.current.getLayer(`polygon-${loc.id}`)) return;

      existingPolygons.current.push([coordinates]);

      mapRef.current.addLayer({
        id: `polygon-${loc.id}`,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [coordinates] },
          },
        },
        layout: {},
        paint: { 'fill-opacity': 0 },
      });

      mapRef.current.addLayer({
        id: `outline-${loc.id}`,
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [coordinates] },
          },
        },
        layout: {},
        paint: { 'line-color': '#4ec274', 'line-width': 1 },
      });

      addPolygonWithLabel([coordinates], loc.location_name, loc.id, parsedLocPoint);
    });
  };

  if (mapRef.current.isStyleLoaded()) {
    console.log("Map style already loaded. Processing polygons.");
    processPolygons();
  } else {
    console.log("Waiting for map style to load...");

    // Attach listener for 'style.load' (use once to prevent multiple calls)
    const onStyleLoad = () => {
      console.log("Map style loaded. Processing polygons.");
      processPolygons();
    };

    mapRef.current.once('style.load', onStyleLoad);

    // **Fallback: If `style.load` doesn't fire, check again after 3 seconds**
    setTimeout(() => {
      if (mapRef.current.isStyleLoaded()) {
        console.log("Fallback triggered: Style loaded after timeout.");
        processPolygons();
      } else {
        console.warn("Map style still not loaded after timeout.");
      }
    }, 500);
  }
}, [locationCordinates, loader, locationList, locationPoint]);

    const checkCoordinates = (draw) => {
        if (draw) {
            const data = draw.getAll();
            if (data.features.length > 0) {
                SetTitle("");
                SetModalLocation(true);
            } else {
                SetTitle(<h4>Please Select Coordinates First</h4>);
                SetModalError(true);
            }
        } else {
            SetTitle(<h4>Please Select Coordinates First</h4>);
            SetModalError(true);
        }
    };

   async function removeLocation() {
        console.log("locationSelected", locationSelected);
        let id = locationSelected;
        if (!id) {
            console.error("Location ID is not valid");
            return;
        }

        console.log(`Removing location with ID: ${id}`);

        const polygonLayerId = `polygon-${id}`;
        const outlineLayerId = `outline-${id}`;
        const labelLayerId = `label-layer-${id}`;

        let requestBody = {
            session: userDetails.session,
            org_id: userDetails.org_id,
            location_id: id,
        };
try {
    SetLoaderAPI(true);
    const res = await removeMapLocation(requestBody);
    console.log("res",res);
    if (res.status == 200) {
      // Remove the marker from the map
      if (markersRef.current[locationSelected]) {
        console.log("in if marker selected");
        markersRef.current[locationSelected].remove();
        delete markersRef.current[locationSelected];
      }else{
        console.log("in else marker selected");
      }

      setLocationPoint((prev) => prev.filter((point) => point.location_id !== locationSelected));
      SetTitle(<h4>Location deleted successfully</h4>);
      SetDescription('');
      SetModalSucess(true);
    } else {
      SetTitle(<h4>Failed to delete Location</h4>);
      SetDescription(res.message);
      SetModalError(true);
    }
  } catch (error) {
    console.error("Error deleting Location:", error);
    SetTitle(<h4>Failed to delete Location</h4>);
    SetModalError(true);
  } finally {
    SetLoaderAPI(false);
    SetDeleteModal(false);
  }


    }
   async function handleRemoveMapLocationBoundary() {
        console.log("locationSelected", locationSelected);
        let id = locationSelected;
        if (!id) {
            console.error("Location ID is not valid");
            return;
        }

        console.log(`Removing location with ID: ${id}`);

        const polygonLayerId = `polygon-${id}`;
        const outlineLayerId = `outline-${id}`;
        const labelLayerId = `label-layer-${id}`;

        let requestBody = {
            session: userDetails.session,
            org_id: userDetails.org_id,
            location_id: id,
        };

        SetLoaderAPI(true);

        try {
    SetLoaderAPI(true);
    const res = await removeMapLocationBoundary(requestBody);
    if (res.status == 200) {
        
      SetLoaderAPI(false);
      let location_serial = [...locationList].find(el=>el.id===locationSelected)?.tag_serial	      
        SetExistLocation((prev) => prev.filter((coord) => coord.location_serial !== location_serial))
        SetLocationCordinates((prev) => prev.filter((coord) => coord.location_serial !== location_serial));

         SetTitle(<h4>Boundary deleted successfully</h4>);
        SetModalSucess(true);
        SetDeleteModalBoundary(false);
        SetLocationSelected('');
     
        if (mapRef.current.getLayer(polygonLayerId)) {
          mapRef.current.removeLayer(polygonLayerId);
        }
        if (mapRef.current.getLayer(outlineLayerId)) {
          mapRef.current.removeLayer(outlineLayerId);
        }
        if (mapRef.current.getLayer(labelLayerId)) {
          mapRef.current.removeLayer(labelLayerId);
        }

      } else {
        SetLoaderAPI(false);
        SetTitle(<h4>Failed to delete boundary</h4>);
        SetDescription(res.message);
        SetModalError(true);
      }
    } catch (error) {
      console.error("Error deleting boundary:", error);
      SetLoaderAPI(false);
      SetTitle(<h4>Failed to delete boundary</h4>);
      SetModalError(true);
    }
    }

    // Helper function to compare polygons
    function arePolygonsEqual(polygon1, polygon2) {
        return JSON.stringify(polygon1) === JSON.stringify(polygon2);
    }

    function addPolygonWithLabel(polygonCoordinates, labelText, locationId,centroid) {      
      console.log("here centroid execute",centroid)
      existingPolygons.current.push(polygonCoordinates);
      console.log("polygonCoordinates, labelText, locationId",polygonCoordinates, labelText, locationId)
      mapRef.current.addSource(`label-${locationId}`, {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: centroid
      },
      properties: {
        title: labelText,  // Label text
      },
    },
  });

  mapRef.current.addLayer({
    id: `label-layer-${locationId}`,
    type: 'symbol',
    source: `label-${locationId}`,
    layout: {
      'text-field': ['get', 'title'],  // Get text from the 'title' property
      'text-size': 10,
      'text-offset': [0, 0.5],  // Adjust the position of the text relative to the point
      'text-anchor': 'top',  // Position the label above the center point
    },
    paint: {
      'text-color': '#000000',  // Label color
    },
  });
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "location_name",
            key: "location_name",
        },
        {
            title: "Area",
            dataIndex: "area_size",
            key: "area_size",
            render: (_, record) => renderActions(record.id, "cordinate", record.location_name),
        },
        {
            title: "Location",
            key: "actions",
            render: (_, record) => renderActions(record.id, "point", record.location_name),
        },
    ];

    return (
        <div>
            {loader ? (
                <div className="loaderContainer">
                    <img src={LoaderSpinner} />
                </div>
            ) : (
                ""
            )}

            <div className="removeBtn" style={{ marginBottom: 20 }}>
                {/* {existLocation.map((el) => {
                    let locExist = locationList.find((elm) => elm.tag_serial === el.location_serial);
                    return (
                        <button
                            className="btn btn-transparent"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                SetLocationSelected(locExist ? locExist.id : null);
                                SetTitle(<span style={{ color: "red" }}>{locExist.location_name}</span>);
                                SetDeleteModalBoundary(true);
                            }}
                        >
                            {locExist ? locExist.location_name : el.location_serial}{" "}
                            <span className="removeIcon">x</span>
                        </button>
                    );
                })} */}
            </div>
            <div style={{ display: 'flex', height: 'calc(100vh - 120px)' }}>
                <div id="container" style={{ flex: 7.5, position: "relative", height: 'calc(100vh - 120px)' }}>
                    <div ref={mapContainerRef} style={{ width: '100%', height: 'calc(100vh - 120px)' }} />
                </div>
                <div style={{ flex: 2.5, overflowY: 'auto', padding: '10px', background: '#f5f5f5', borderLeft: '1px solid #ddd' }}>
                    <Table
                        dataSource={locationList}
                        columns={columns}
                        rowKey="id"
                        pagination={false}
                    />
                </div>

                {!loader ? (
                    <div className="absGreatStyle" style={{ width: widthBox }}>
                        <div onClick={() => SetWidthBox((prev) => (prev === 80 ? 480 : 80))} className="currentStyle boxStyle">
                            <img src={currentModeImg} />
                            <small>{currentMode}</small>
                        </div>
                        <div className="expandMode">
                            {mapBoxMode.map(el => (
                                <div
                                    key={el.title}
                                    className={el.title === currentMode ? 'boxStyle active' : 'boxStyle'}
                                    onClick={() => changeMapStyle(el.function, el.title, el.image)}
                                >
                                    <img src={el.image} />
                                    <small>{el.title}</small>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>


            <WarningModal
                modalWarning={deleteModalBoundary}
                handleDeleteConfirm={handleRemoveMapLocationBoundary}
                handleCloseModalWarning={() => { SetDeleteModalBoundary(false); SetLocationSelected(''); }}
                title={title}
            />
            <WarningModal
                modalWarning={deleteModal}
                handleDeleteConfirm={removeLocation}
                handleCloseModalWarning={() => { SetDeleteModal(false); SetLocationSelected(''); }}
                title={title}
            />
            <SucessModal
                modalSucess={modalSucess}
                handleCloseModalSucess={handleCloseModalSucess}
                title={title}
                description={description}
                key={'addEmployee'}
            />
            <ErrorModal
                modalError={modalError}
                handleCloseModalError={handleCloseModalError}
                title={title}
                description={description}
                key={'addEmployeeError'}
            />
        </div>
    );
};

export default MapComponentV3;
