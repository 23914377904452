import React, { useState, useEffect,useRef } from 'react'
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../styles/styles.scss'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import {Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as Setting} from '../../assets/images/setting.svg';

import {ReactComponent as EditIcon} from '../../assets/images/pen-to-square.svg'
import {ReactComponent as AddIcon} from '../../assets/images/square-plus.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/trash-can-xmark.svg'
import { Select } from 'antd';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {getOrganizationList,getOemOrgDetail,removeOrg, CreteOrg, EditOrg} from '../actionMethods/actionMethods'
import LoaderSpinner from '../../assets/images/loader.svg'
import Createorg from "../../organizationmanagement/components/create"
import Createsuborg from "../../organizationmanagement/components/addsuborg"
import traceplusLogo from '../../assets/traceplusImages/trace_logo.png';
import dots from '../../assets/images/dots.png';
import check from '../../assets/images/check.png'
import uncheck from '../../assets/images/cancel.png'
import Scrollbars from 'react-custom-scrollbars';
import SucessModal from '../../components/SucessModal';
import ErrorModal from '../../components/ErrorModal';
import { getsuborganizationlist } from '../../usermanagement/actionMethods/actionMethods';
import {ReactComponent as ArrowRight} from '../../assets/images/arrow-right-solid.svg'
const { Option } = Select;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        maxHeight: '90%'
    },
};

function OrgList(props){
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];
    let history = useHistory();
    const [modalGroup,SetModalGroup]= useState(false);  
    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [modalEdit,SetModalEdit]=useState(false);
    const[WidthContainer,setWidthContainer]=useState('');
    const [searchRole,SetSearchRole]=useState(props.searchRole);
    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  
    const [orgListData,SetOrgListData] =useState([]);
    const [orgListDefaultData,SetOrgListDefaultData] =useState([]);
    const [loader,SetLoader]=useState(true);
    const [modalCreate,SetModalCreate]=useState(false);
    const [modalUpdate,SetModalUpdate]=useState(false);
    const [title,SetTitle] =useState('');
    const [description,SetDescription] =useState('');
    const [modalSucess,SetModalSucess]= useState(false);
    const [modalError,SetModalError]= useState(false);
    const [suborglist,setsuborglist] =useState([])
    const[suborgdata,Setsuborgdata]=useState({})
const [inc,SetInc]=useState(1);
    const [searchOrg,SetSearchOrg]=useState(props.searchOrg);
    const [permission,SetPermission]=useState(props.permission)
    const [apiCall,SetAPICall]=useState(false)
    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';

    const [activeIndex, setActiveIndex] = useState(null);
    const[selectedorg,Setselectedorg]=useState("")
    const handleMenuClick = (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    useEffect(()=>{

        SetPermission(props.permission);
    },[props.permission])

useEffect(()=>{
    if(userDetails.firstLoad){
        // console.log("firstload in if",userDetails.firstLoad)
        history.push('/organization-management')
    }else{
        // console.log("firstload in else",userDetails.firstLoad)
        props.history.push('/dashboard')
    }
        
    },[])

    useEffect(()=>{
        let requestBody={}
        requestBody.session=userDetails.session;
        requestBody.org_id=userDetails.org_id;
        getOemOrgDetail(requestBody).then(res=>{
            if(res.status==200){
                console.log("oem response",res.data);
                let deviceData= res.data;

                getOrganizationList(requestBody).then(res2=>{
                    if(res2.status==200){
                        let data = res2.data;
                        let index2 = 1;
                        let dataFormat =[];
                        
                        Object.keys(data).map(function(key){
                            // console.log(dataFormat)

                            // data[key].id
                            let active_asset_count=deviceData.active_asset_count?deviceData.active_asset_count[data[key].id]?deviceData.active_asset_count[data[key].id]:0:0;
                            let active_employee_count=deviceData.active_employee_count?deviceData.active_employee_count[data[key].id]?deviceData.active_employee_count[data[key].id]:0:0;
                            let active_gateway_count=deviceData.active_gateway_count?deviceData.active_gateway_count[data[key].id]?deviceData.active_gateway_count[data[key].id]:0:0;
                            let active_location_count=deviceData.active_location_count?deviceData.active_location_count[data[key].id]?deviceData.active_location_count[data[key].id]:0:0;
                            let active_temporary_employee_count=deviceData.active_temporary_employee_count?deviceData.active_temporary_employee_count[data[key].id]?deviceData.active_temporary_employee_count[data[key].id]:0:0;
                            let asset_low_battery_devices=deviceData.asset_low_battery_devices?deviceData.asset_low_battery_devices[data[key].id]?deviceData.asset_low_battery_devices[data[key].id]:0:0
                            let available_employee_count=deviceData.available_employee_count?deviceData.available_employee_count[data[key].id]?deviceData.available_employee_count[data[key].id]:0:0
                            let available_location_count=deviceData.available_location_count?deviceData.available_location_count[data[key].id]?deviceData.available_location_count[data[key].id]:0:0
                            let employee_low_battery_devices=deviceData.employee_low_battery_devices?deviceData.employee_low_battery_devices[data[key].id]?deviceData.employee_low_battery_devices[data[key].id]:0:0
                            let inactive_asset_count=deviceData.inactive_asset_count?deviceData.inactive_asset_count[data[key].id]?deviceData.inactive_asset_count[data[key].id]:0:0
                            let inactive_gateway_count=deviceData.inactive_gateway_count?deviceData.inactive_gateway_count[data[key].id]?deviceData.inactive_gateway_count[data[key].id]:0:0
                            let inactive_visitor_count=deviceData.inactive_visitor_count?deviceData.inactive_visitor_count[data[key].id]?deviceData.inactive_visitor_count[data[key].id]:0:0
                            let total_number_of_devices=deviceData.total_number_of_devices?deviceData.total_number_of_devices[data[key].id]?deviceData.total_number_of_devices[data[key].id]:0:0
                            let inactive_temporary_employee_count = deviceData.inactive_temporary_employee_count?deviceData.inactive_temporary_employee_count[data[key].id]?deviceData.inactive_temporary_employee_count[data[key].id]:0:0
                            dataFormat.push({
                                id:data[key].id,
                                org_name:data[key].name,
                                org_logo:data[key].logo_url?data[key].logo_url.replace("https://traceplus.ai/wphttps:","https:"):null,
                                active_asset_count:active_asset_count,
                                active_employee_count:active_employee_count,
                                active_gateway_count:active_gateway_count,
                                active_location_count:active_location_count,
                                active_temporary_employee_count:active_temporary_employee_count,
                                inactive_temporary_employee_count:inactive_temporary_employee_count,
                                asset_low_battery_devices:asset_low_battery_devices,
                                available_employee_count:available_employee_count,
                                available_location_count:available_location_count,
                                employee_low_battery_devices:employee_low_battery_devices,
                                inactive_asset_count:inactive_asset_count,
                                inactive_gateway_count:inactive_gateway_count,
                                inactive_visitor_count:inactive_visitor_count,
                                total_number_of_devices:total_number_of_devices,
                                device:false,
                                sub_org:true,
                            })
                            index2++;
                        });
                        console.log("Api res2ponse",data,dataFormat)
                        SetOrgListData(dataFormat);
                        SetOrgListDefaultData(dataFormat)
                        SetAPICall(true)
                        localStorage.removeItem('timezonename')
                            SetLoader(false)
                            let orglist = data
                            //console.log("orglist", orglist)
                            orglist.forEach((org)=>{
                                let requestBody={};
                                requestBody.session=userDetails.session;
                                requestBody.org_id = org.id
                                getsuborganizationlist(requestBody).then(res=>{
                                  if(res.status==200){
                                      let data = res.data;
                                      let index2 = 1;
                                      let dataFormat1 =[];
                                      Object.keys(data).map(function(key){
                                          // console.log(dataFormat)
                                          dataFormat1.push({id:data[key].id,org_name:data[key].name,org_id:data[key].org_id})
                                          index2++;
                                      });
                                      // console.log("Api Response",data,dataFormat)
                                      setsuborglist(dataFormat1);
                                      Setsuborgdata(prevState => {
                                        
                                        const updatedObject = { ...prevState };

                                       // console.log("sublostdata",updatedObject)
                                        updatedObject[String(requestBody.org_id)]=data
                    
                                        return updatedObject;
                                    })
                                      SetAPICall(true)
                                     //console.log("Api Response",dataFormat1)
                                          SetLoader(false)
                                  }
                                  if(res.status==300){
                                      localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                                      localStorage.removeItem('userLoginDetailsTraceAdmin')
                                      props.history.push(`/login`)
                                  }
                                  //console.log("suborglistnew", suborgdata)
                              
                              }).catch()
                            })
                    }else{
                        SetLoader(false)
                    }
                    // if(res2.status==300){
                    //     localStorage.removeItem('isLoggedInGatewayTraceAdmin')
                    //     localStorage.removeItem('userLoginDetailsTraceAdmin')
                    //     localStorage.removeItem('timezonename')
                    //     props.history.push(`/login`)
                    // }

                }).catch();
            }

        });
    },[inc])
 

    useEffect(()=>{
        let data = [...orgListDefaultData];
let arr=  data.filter((item) => {
                     return item.org_name.toString().toLowerCase().includes(props.searchorg.toLowerCase());
                    })
        SetOrgListData(arr);           
},[props.searchorg])
    
    useEffect(() => {
        let data = [...orgListDefaultData]
        let arr=[];
        if(sortKey=='org'){
            arr = data.sort((a, b) => {
                
                a = a.org_name.toUpperCase()
                b = b.org_name.toUpperCase()
                return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
            })
        }
        
   
        SetOrgListData(arr);

    },[sort,sortKey])
    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

    


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val,type='secondary'){
        
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
     }  
      else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }
        

    }


    function editHandlerAlert(id){
        SetModalEdit(true);
    }
    function Orgdata(orgdata){

    }
    function Reload(){
        SetInc((prev)=>prev+1);
    }
    
    function modalSucessHandler(titleShow,descriptionShow){
        SetTitle(titleShow)
        SetDescription(descriptionShow)
        SetModalSucess(true);
        
    }
    function handleCloseModalSucess(){
        SetModalCreate(false);
        SetModalUpdate(false)
        SetModalSucess(false)
        SetModalEdit(false);
        SetInc((prev)=>prev +1);
     
    }
    function handleCloseModalError(){
        SetModalError(false)
    }
    
    function modalErrorHandler(titleShow,descriptionShow){
        SetTitle(titleShow)
        SetDescription(descriptionShow)
        SetModalError(true);
    }
    function handleCloseModalCreate(){
        SetModalCreate(false);
        SetModalGroup(false)
    }

    const renderMenu = (index,data) => {
        if (activeIndex === index) {
          return (
            <div className="popup">
              <ul>
                <li>
                  <button className='btn btn-smaller btn-icon hover-message btn-event-create' onClick={()=>{props.modalOpenCategoryEdit(data);setActiveIndex(null)}}>
                    <EditIcon /> 
                    <span>Edit</span>
                  </button>
                </li>
                <li>
                  <button className='btn btn-smaller btn-icon hover-message btn-event-create' onClick={()=>{props.modalDeleteModal(data);setActiveIndex(null)}}>
                    <DeleteIcon />
                    <span>Delete</span>
                  </button>
                </li>
                {/* <li>
                  <button className='btn btn-smaller btn-icon hover-message btn-event-create' onClick={()=>{props.UserHandler();setActiveIndex(null)}}>
                  <Setting/><span>Settings</span>
                  </button>
                </li> */}
                {/* <button className='btn btn-smaller btn-icon  hover-message btn-event-create' ><Setting/><span>Settings</span></button> */}
              </ul>
            </div>
          );
        }
        return null;
      };

      function SubOrgdata1(selectedorgn,selectedsub,org_logo){
    
        var LoginDetails ;
        const data ={}
        console.log("selectedorgn",org_logo);
        //console.log(localStorage.getItem('userLoginDetailsTraceAdmin'))
        // LoginDetails = JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin'))
       
         LoginDetails =  localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
        //console.log("type",LoginDetails["org_id"])
        LoginDetails["org_id"]= String(selectedorgn);
        LoginDetails["sub_org_id"]=String(selectedsub);
        
        // LoginDetails["org_logo"] = org_logo;
        LoginDetails["firstLoad"] = false;
        LoginDetails["org_logo2"] = org_logo
        props.changeFirstLoad();
        // console.log(orgdata, LoginDetails.type)
        localStorage.removeItem('userLoginDetailsTraceAdmin')

        localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
        
        
        //history.push('/team-management')
        
          history.push('/dashboard');
          
       
       
    }
      function Orgdata(orgID){
    
        var LoginDetails ;
        const data ={}
         LoginDetails =  localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
        //console.log("type",LoginDetails["org_id"])
        LoginDetails["org_id"]= String(orgID);
        
        LoginDetails["firstLoad"]= true;
    //     const selectedOrgData1 = orgListData.find((org) => org.id === orgID);
      
    //   if (selectedOrgData1) {
    //     LoginDetails["org_logo"] = selectedOrgData1.org_logo;
    //   }
        // console.log(orgdata, LoginDetails.type)
         localStorage.removeItem('userLoginDetailsTraceAdmin')
        localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
    //     const selectedOrgData = orgListData.find((org) => org.id === selectedOrg);
    //   const selectedSubOrgData = suborglist.find((suborg) => suborg.org_id === selectedOrg);
    
    //   if (selectedSubOrgData) {
    //     setModalIsOpensub(true);
    //   } else if (selectedOrgData) {
    //     LoginDetails["sub_org_id"] = "0"
    
    //     localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
    //     setModalIsOpen(false);
        // let url = window.location.pathname;
        // history.push("/team-management");
        
        
          history.push("/dashboard");
        
    //   }
    }
    const [isListVisible, setListVisible] = useState(false);

    const [openSuborgId, setOpenSuborgId] = useState(null);
    const handleClick = (suborgId) => {
        console.log("suborgid",suborgId)
        setOpenSuborgId((prevId) => (prevId === suborgId ? null : suborgId));
      setListVisible(!isListVisible);
    };
    function redirectCLientPage(id,org_logo){
        var LoginDetails ;
        const data ={}
        console.log("selectedorgn",org_logo);
        //console.log(localStorage.getItem('userLoginDetailsTraceAdmin'))
        // LoginDetails = JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin'))
       
         LoginDetails =  localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
        //console.log("type",LoginDetails["org_id"])
        LoginDetails["org_id"]= String(id);
        LoginDetails["sub_org_id"]=String(0);
        
        // LoginDetails["org_logo"] = org_logo;
        // LoginDetails["firstLoad"] = false;
        LoginDetails["org_logo2"] = org_logo
        // props.changeFirstLoad();
        // console.log(orgdata, LoginDetails.type)
        localStorage.removeItem('userLoginDetailsTraceAdmin')

        localStorage.setItem('userLoginDetailsTraceAdmin',JSON.stringify(LoginDetails))
        history.push('/client-app-management')
    }
function ShowCardListOrg(data){
    let arr= []

    console.log("data",data);
    let index = 1;
   
    if(data.length >0 ){
        
    for(let i=0;i<data.length; i++){
        //data[key].reported_time
        arr.push(
            <div className="orgBox" key={'contentGroup'+i} >
                
                <div className='innerSUb'>
                   
                    <div className='innerSUbTitleArea'> 
                    <div className='logourlclass'><h4 style={{textAlign:"left"}}>{data[i].org_logo?<img src={data[i].org_logo} style={{minWidth:120,maxHeight:50}} />:<img src={traceplusLogo}/>}</h4><div className='threeDotted1'> </div>
                  </div>
                <h4><span style={{display:"block"}} onClick={() =>(suborgdata[String(data[i].id)].length === 0)?Orgdata(data[i].id):""}>{data[i].org_name.toLowerCase()}</span></h4><div className='threeDotted'> <img src={dots} onClick={() => handleMenuClick(i)} />
              {renderMenu(i,data[i])}</div></div>
                    <div className='innerSubContentArea'>
                        {/* <div className='firstArea '>Mumbai | abc@test.com</div> */}
                        <div className={data[i].sub_org?'secondArea active':'secondArea'} onClick={()=>SetOrgListData((prev)=>{let item =[...prev];item[i].sub_org=true;item[i].device=false;return item  })}>Sub Org: {suborgdata[String(data[i].id)]? suborgdata[String(data[i].id)].length:0}</div>
                        <div className={data[i].device?'secondArea active':'secondArea'} onClick={()=>SetOrgListData((prev)=>{let item =[...prev];item[i].device=true;item[i].sub_org=false;return item  })} >Devices : {data[i].total_number_of_devices}</div>
                        <div className='secondArea' onClick={()=>redirectCLientPage(data[i].id,data[i].org_logo) }>Add Page</div>
                    
                        {data[i].sub_org ?
                        (

        <Scrollbars style={{ height: '200px' }}>
          <div className='lastArea'>
          <Row style={{margin:"0px"}}>
          <Col lg={12} className='titleAreaTag noBorder'>Add sub Organization<button className='btn btn-smaller btn-icon hover-message btn-event-create' onClick={(e)=>{SetModalGroup(true);Setselectedorg(data[i].id)}}>
                    <AddIcon />
                  </button></Col>
          </Row>
          <ul className="suborg-list">
  {suborgdata[String(data[i].id)]  && suborgdata[String(data[i].id)].length>0?
    suborgdata[String(data[i].id)].map((suborg, index) => (
      <li key={index+suborg.org_id} onClick={()=>SubOrgdata1(suborg.org_id,suborg.id,data[i].org_logo)}>{data[i].org_name+" - "+suborg.name}<span className='arrowRIght'><ArrowRight/></span></li>
    )):<li key={"viewDepartment"} onClick={()=>SubOrgdata1(data[i].id,0,data[i].org_logo)}>{data[i].org_name}<span className='arrowRIght'><ArrowRight/></span></li>}
</ul>

          </div>
        </Scrollbars>
      ):(
                        <Scrollbars style={{height:"200px",}}>
<div className='lastArea'>
                            
                            <Row style={{margin:"0px"}}>
                                <Col lg={12} className='titleAreaTag noBorder'>Regular Worker Tag</Col>
                                <Col lg={12}><img src={check}/> {data[i].active_employee_count} Active </Col>
                                <Col lg={12}><img src={uncheck}/> - Inactive</Col>
                                <Col lg={12} className='titleAreaTag'>Location Tag</Col> 
                                <Col lg={12}><img src={check}/> {data[i].active_location_count} Active</Col>
                                <Col lg={12}><img src={uncheck}/> - Inactive</Col>
                                <Col lg={12} className='titleAreaTag'>Gateway</Col> 
                                <Col lg={12}><img src={check}/> {data[i].active_gateway_count} Active</Col>
                                <Col lg={12}><img src={uncheck}/> {data[i].inactive_gateway_count} Inactive</Col>
                                <Col lg={12} className='titleAreaTag'>Asset</Col> 
                                <Col lg={12}><img src={check}/> {data[i].active_asset_count} Active</Col>
                                <Col lg={12}><img src={uncheck}/> {data[i].inactive_asset_count} Inactive</Col>
                                 <Col lg={12} className='titleAreaTag'>Visitor</Col> 
                                <Col lg={12}><img src={check}/> {data[i].active_asset_count} Active</Col>
                                <Col lg={12}><img src={uncheck}/> {data[i].inactive_visitor_count} Inactive</Col>
                                <Col lg={12} className='titleAreaTag'>Daily Worker Tag</Col> 
                                <Col lg={12}><img src={check}/> {data[i].active_temporary_employee_count} Active </Col>
                                <Col lg={12}><img src={uncheck}/> {data[i].inactive_temporary_employee_count} Inactive</Col>
                            </Row>
                        </div>
                        </Scrollbars>)}
                    </div>

                </div>


            </div>
        )
index++;
            }
        }else if(apiCall===false){
            
        }
        else{
           arr.push( 
                    <Row className='contentGroup nofound' key={'contentGroup nofound'}>
                        <div className='group-header'>
                            <Col className='align-middle'>
                                <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                            </Col>
                        </div>
                    </Row>
                    ) 
        }
        arr.push(           
            <div className="orgBox" key={'contentGroupCreate'}>
                    <div className="pinkBackground" key={'contentGroupCreate'} >
                            <div style={{ marginTop:"70px",backgroundColor:"transparent" }} className='btn-transparent btn-icon btn-event-create' onClick={(e)=>(SetModalCreate(true))}>
                                <h2><AddIcon style={{ height: '35px' }} className='icon'/></h2>
                                <h2 style={{fontSize:20}}> Create Organization</h2>
                                
                            </div>
                    </div>
         </div>)
    return arr;
}

    
    
    return(

        <div className='orgList'> 
        
        {loader?<div className='loaderContainer1'><img src={LoaderSpinner} /></div>:
        
        ShowCardListOrg(orgListData)}
            
            <ReactModal
                        isOpen={modalCreate}
                        style={customStyles}
                        // onRequestClose={handleclosemodal}
                        className={'create-form-user'}
                    >
                           <Createorg
        
                           handleCloseModal={handleCloseModalCreate}
                           modalSucessHandler={modalSucessHandler}
                           modalErrorHandler={modalErrorHandler}
                           Reload={Reload}
                        />
                    </ReactModal>
                
                    <ReactModal
                isOpen={modalGroup}
                style={customStyles}
                // onRequestClose={handleCloseModal}
              >
                   
              <Createsuborg handleCloseModal={handleCloseModalCreate} Reload={Reload}  selectedorg={selectedorg} />
            </ReactModal>
                    <SucessModal modalSucess={modalSucess} handleCloseModalSucess={handleCloseModalSucess} title={title} description={description} />
                <ErrorModal modalError={modalError} handleCloseModalError={handleCloseModalError}   title={title} description={description}  />    
        
        </div>
    )
}
export default OrgList;