import { ReactComponent as DashboardIcon }  from '../assets/images/tachometer-alt-solid.svg'
import { ReactComponent as DashboardIconActive }  from '../assets/images/tachometer-alt-solid-active.svg'

import { ReactComponent as SiteICon }  from '../assets/images/siteManagementDefault.svg'
import { ReactComponent as SiteIConActive }  from '../assets/images/siteManagementActive.svg'


import { ReactComponent as UserIcon }  from '../assets/images/userMenu.svg'
import { ReactComponent as UserIconActive }  from '../assets/images/userMenu-active.svg'

import  { ReactComponent as DeviceIcon }  from '../assets/images/deviceManagement.svg'
import  { ReactComponent as DeviceIconActive }  from '../assets/images/deviceManagementActive.svg'

import { ReactComponent as RoleIcon }  from '../assets/images/role.svg'
import { ReactComponent as RoleIconActive }  from '../assets/images/role-active.svg'

import {ReactComponent as Visitor} from '../assets/images/visitor-default.svg'
import {ReactComponent as VisitorIconActive} from '../assets/images/vistor-active.svg'

import {ReactComponent as ProductiveIcon} from '../assets/images/productive.svg'
import {ReactComponent as ProductiveIconActive} from '../assets/images/productive-active.svg'

import {ReactComponent as Shift} from '../assets/images/clock-regular.svg'
import {ReactComponent as ShiftActive} from '../assets/images/clock-active.svg'
import {ReactComponent as server} from '../assets/images/database.svg'
import {ReactComponent as MapLocation} from '../assets/images/map-location.svg'
import {ReactComponent as FileImport} from '../assets/images/file-import.svg'
import {ReactComponent as Team} from '../assets/images/users-medical.svg'
import {ReactComponent as Organization} from '../assets/images/organization.svg'
import {ReactComponent as Supervisor} from '../assets/images/supervisor2.svg'

import {ReactComponent as ClockIcon } from '../assets/images/clock.svg'
import {ReactComponent as ClockIconActive}from '../assets/images/clockActive.svg'

import {ReactComponent as CounterIcon } from '../assets/images/facilityCounter.svg'
import {ReactComponent as CounterIconActive}from '../assets/images/facilityCounterActive.svg'

import {ReactComponent as HolidayIcon } from '../assets/images/calendar-star.svg'

import {ReactComponent as DeviceMonitorIcon } from '../assets/images/router.svg'
import {ReactComponent as DeviceMonitorIconActive } from '../assets/images/routerActive.svg'

import {ReactComponent as ClientAppIcon } from '../assets/images/file-plus.svg'
import {ReactComponent as ClientAppIconActive } from '../assets/images/plus.svg'

import {ReactComponent as ProcessIcon} from '../assets/images/folder-open.svg';

import {ReactComponent as PlanImport} from '../assets/images/file-invoice.svg';

export const sidebarMenuItems = [

    {
        "title": "Org Management",
        "paths":"/organization-management",
        "tooltip":"Policy Management",
        "icon":Organization,
        "activeicon":Organization
      
    },
    {
        "title": "Web App Page Management",
        "paths":"/webapp-page",
        "tooltip":"Web App Page Management",
        "icon":RoleIcon,
        "activeicon":RoleIconActive
      
    },
    {
        "title": "Dashboard",
        "paths":"/dashboard",
        "tooltip":"Dashboard",
        "status":"active",
        "icon":DashboardIcon,
        "activeicon":DashboardIconActive
    },
    {
        "title": "Geo Map V2",
        "paths":"/petronas-map",
        "tooltip":"petronas-map",
        "icon":MapLocation,
        "activeicon":MapLocation
    }, {
        "title": "Geo Map V3",
        "paths":"/geo-map-management-v3",
        "tooltip":"Geo Map V3",
        "icon":MapLocation,
        "activeicon":MapLocation
    },
    {
        "title": "Client App Management",
        "paths":"/client-app-management",
        "tooltip":"Dashboard",
        "status":"active",
        "icon":ClientAppIcon,
        "activeicon":ClientAppIcon
    },
    // {
    //     "title": "Supervisor Management",
    //     "paths":"/Supervisor-management",
    //     "icon":Supervisor,
    //     "activeicon":Supervisor
      
    // },
    {
        "title": "Role Management",
        "paths":"/role-management",
        "status":"active",
        "icon":RoleIcon,
        "activeicon":RoleIconActive
    },
    {
        "title": "User Management",
        "paths":"/user-management",
        "icon":UserIcon,
        "activeicon":UserIconActive,
    },
    


    // {
    //     "title": "Asset Management",
    //     "paths":"/asset-management",
    //     "icon":Visitor,
    //     "activeicon":VisitorIconActive
    // },
    {
        "title": "Team Management",
        "paths":"/team-management",
        "icon":Team,
        "activeicon":Team
    },
    
    {
        "title": "Plan Import",
        "paths":"/plan-import",
        "icon":PlanImport,
        "activeicon":PlanImport,
     },
    {
        "title": "Mass Upload",
        "paths":"/mass-upload",
        "icon":FileImport,
        "activeicon":FileImport,
     },
    {
        "title": "Cycle Count",
        "paths":"/cycle-count-management",
        "icon":ClockIcon,
        "activeicon":ClockIconActive,
    },


    // {
    //     "title": "Shift Management",
    //     "paths":"/shift-management",
    //     "icon":Shift,
    //     "activeicon":ShiftActive
    // },
    {
        "title": "Productive Management",
        "paths":"/productive-management",
        "icon":ProductiveIcon,
        "activeicon":ProductiveIconActive
    },
    {
        "title": "Site Management",
        "paths":"/site-management",
        "tooltip":"Site Management",
        "status":"active",
        "icon":SiteICon,
        "activeicon":SiteIConActive
    },

    {
        "title": "Facility Counter",
        "paths":"/facility-counter",
        "icon":CounterIcon,
        "activeicon":CounterIconActive,
    },
{
        "title": "Process Management",
        "paths":"/process-management",
        "tooltip":"Process Management",
        "icon":ProcessIcon,
        "activeicon":ProcessIcon
    },

    {
        "title": "Holiday Management",
        "paths":"/holiday",
        "icon":HolidayIcon,
        "activeicon":HolidayIcon,
    },
        {
        "title": "Device Monitor",
        "paths":"/device-monitor",
        "tooltip":"Device Monitor",
        "icon":DeviceMonitorIcon,
        "activeicon":DeviceMonitorIconActive
    },
    {
        "title": "Device Management",
        "paths":"/device-management",
        "tooltip":"Policy Management",
        "icon":DeviceIcon,
        "activeicon":DeviceIconActive
    },
    
    {
        "title": "Geo Map Management",
        "paths":"/geo-map-management",
        "tooltip":"Policy Management",
        "icon":MapLocation,
        "activeicon":MapLocation
    },
    {
        "title": "Registered Workers",
        "paths":"/employee-management",
        "tooltip":"Policy Management",
        "icon":server,
        "activeicon":server
    }
   
]